export const strings = {
  newly_added_post: 'Newly Added Posts',
  registered: 'Registered',
  group_activities: 'Group Activities',
  webinar_analytics: 'Webinar Analytics',
  analytics_overview: 'Analytics Overview',
  analytics_total: 'Analytics By Total',
  total_no_of_members: 'Total Number of Members',
  total_no_of_groups: 'Total Number of Groups',
  upcoming_webinar: 'Upcoming Webinars',
  total_webinars: 'Total Webinars',
  total_no_registration: 'No. of Registrations',
  for_upcoming_webinar: 'For Upcoming Webinars',
  total_number_of_registrations: 'Total No. Of Registrations',
  total_registration: 'Total Registration',
  total_activities: 'Total Activities',
  no_of_members_joined: 'No. Of Members Joined',
  activities: 'Activities = Post, Like & Comment',
  total_no_posts: 'Total Number of Posts',
  total_no_likes: 'Total Number of Likes',
  comments: 'Comments',
  microsite_analytics: 'Microsite Analytics',
  microsite_active_members: ' Microsite Active Members',
  likes: 'Likes',
  posts: 'Posts',
  present: '- Present',
  webinars: 'Webinars',
  webinar: 'Webinar',
  members: 'Members',
  groups: 'Groups',
  member: 'Member',
  group: 'Group',
  total_cases: 'Total Cases',
  total_cases_questions: 'Total Cases/Questions',
  total_open_cases: 'Total Open Cases',
  total_open_cases_questions: 'Total Open Cases/Questions',
  total_closed_cases: 'Total Closed Cases',
  total_comments: 'Total Comments',
  num_total_comments: '{num} Comments',
  reminders_sent: 'Reminders Sent',
  num_reminders_sent: '{num} Reminders',
  last_login: 'Last Login',
  num_total_cases: '{num} Total Cases',
  num_total_cases_questions: '{num} Total Cases/Questions',
  resolved_cases: 'Resolved Cases',
  resolved_cases_questions: 'Resolved Cases/Questions',
  num_resolved_cases: '{num} Resolved Cases',
  num_resolved_cases_questions: '{num} Resolved Cases/Questions',
  num_open_cases: '{num} Open Cases',
  avg_time_case_resolve: 'Avg time to resolve cases',
  avg_time_case_question_resolve: 'Avg time to resolve cases/questions',
  total_hcp: 'Total HCPs',
  num_total_hcp: '{num} HCPs',
  active_hcp: 'Active HCPs',
  num_active_hcp: '{num} HCPs',
  avg_rating: 'Average Ratings',
  num_avg_rating: '{num} / 5',
  overview: 'Overview',
  export_case: 'Export Cases',
  export_panel: 'Export Panel',
  total_hcp_tooltip:
    'The total HCPs consist of all Experts and HCPs (excluding Admins) signed up to this organization',
  active_hcp_tooltip:
    'All Experts and HCPs (excluding Admins) active in the last 24 Hours',
  pending_tooltip: 'Entities that are currently being reviewed by the screener',
  returned_tooltip:
    'Entities that have been reviewed by the screener and are currently returned to the hcp to be reviewed and resubmitted',
  newcases_hcp_tooltip: 'Entities assigned to the Panel in the last 24 Hours',
  open_tooltip: 'Entities that are open',
  popup_blocker: 'Error!',
  popup_blocker_description:
    'An AdBlock / Popup blocker in your browser is causing an issue while downloading the file, please disable the blocker from this website or click on the button below to download.',
};
