import { Parser } from 'html-to-react';
import linkifyHtml from 'linkifyjs/html';
import { clone, escapeRegExp, groupBy, isEmpty } from 'lodash';
import SecureLS from 'secure-ls';
import { strings } from '../assets/strings/all';
import { APP_ENV, APP_DOMAIN } from './constants';
import { formatDuration, intervalToDuration } from 'date-fns';
import moment from 'moment';
import Cookies from 'js-cookie';

export const storeDataToLocalStorage = async (key, value, encrypt) => {
  if (encrypt) {
    let encryptStorage = new SecureLS({
      encodingType: 'rabbit',
      isCompression: false,
    });
    await encryptStorage.set(key, value);
  } else {
    await localStorage.setItem(key, JSON.stringify(value));
  }
};

export const getUserDataFromLocalStorage = (key, isEcnrypted) => {
  const user = getDataFromLocalStorage('user', true);

  return user;
};

export const getTokenFromLocalStorage = () => {
  const token = getDataFromLocalStorage('token', true);
  return token;
};

export const getHcpSpaceTokenFromLocalStorage = () => {
  let token = getDataFromLocalStorage('user', true);
  if (token) {
    token = token.access_token;
  }
  return token;
};

export const getDataFromLocalStorage = (key, isEcnrypted) => {
  if (isEcnrypted) {
    let ls = new SecureLS({
      encodingType: 'rabbit',
      isCompression: false,
    });

    return ls.get(key);
  }
  return localStorage.getItem(key);
};

export const arrayRemove = (arr, value) => {
  return arr.filter(function (ele) {
    return ele !== value;
  });
};

export const setUpUser = (context, profile) => {
  storeDataToLocalStorage('user', profile, true);
  context.profile(profile);
};

export const setUpToken = async (props, context, token) => {
  storeDataToLocalStorage('token', token, true);
  setTimeout(async () => {
    if (context) {
      await context.login();
    }
    // props.history.replace('/setting-up');
  }, 100);
};

export const getSubdomainName = () => {
  let sub = '';

  if (!IS_LOCALHOST()) {
    const full = window.location.host;
    const parts = full.split('.');

    if (parts.length === 4) {
      sub = parts[0];
    }
  } else {
    const subString = getQueryParameter('org');
    if (subString) {
      sub = subString;
    }
  }

  let subLocal = getDataFromLocalStorage('organization', true);
  if (sub !== '' && !sub) {
    sub = subLocal;
  }

  if (!subLocal || sub !== subLocal) {
    storeDataToLocalStorage('organization', sub, true);
  }

  return sub;
};

export const parseGraphqlErrors = (error) => {
  if (error && error.graphQLErrors && error.graphQLErrors[0]) {
    const errorObj = error.graphQLErrors[0];
    return errorObj;
  }

  return {};
};

export const removeDataFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const hasPermission = (permissionArray, permissionName) => {
  if (isEmpty(permissionArray)) return false;
  return permissionArray.indexOf(permissionName) > -1;
};

export const userHasPermission = (user, permissionName) => {
  const { permissionArray } = user;

  return hasPermission(permissionArray, permissionName);
};

export const hasCasePermission = (singleCase, permissionName) => {
  const { permissionArray } = singleCase;

  return hasPermission(permissionArray, permissionName);
};

export const clearLocalStorage = (complete) => {
  if (complete) {
    localStorage.clear();
  } else {
    Object.keys(localStorage).reduce(function (obj, str) {
      if (
        str !== 'cookie' &&
        str !== 'locale' &&
        str !== '_secure__ls__metadata'
      ) {
        removeDataFromLocalStorage(str);
      }
      return true;
    }, {});
  }
};

export const setTitle = (title) => {
  document.title = title;
};

export const updateQueryString = (key, value, props) => {
  let currentUrlParams = new URLSearchParams(props.location.search);
  currentUrlParams.set(key, value);
  props.history.replace(
    props.location.pathname +
      '?' +
      currentUrlParams.toString() +
      props.location.hash,
  );
};

export const getQueryParameter = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const validatePassword = (p) => {
  let error = '';
  const { auth: authString } = strings;
  if (p.search(/[a-z]/) < 0) {
    error = authString.password_lowercase_auth;
  } else if (p.search(/[A-Z]/) < 0) {
    error = authString.password_uppercase_auth;
  } else if (p.search(/[0-9]/) < 0) {
    error = authString.password_number_auth;
  } else if (p.search(/[@_*#]/) < 0) {
    error = authString.password_character_auth;
  } else if (p.length < 8) {
    error = authString.password_limit_auth;
  }
  return error;
};

export const escapeHtml = (unsafe) => {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
};

/**
 * Add or update a query string parameter. If no URI is given, we use the current
 * window.location.href value for the URI.
 *
 * Based on the DOM URL parser described here:
 * http://james.padolsey.com/javascript/parsing-urls-with-the-dom/
 *
 * @param   (string)    uri     Optional: The URI to add or update a parameter in
 * @param   (string)    key     The key to add or update
 * @param   (string)    value   The new value to set for key
 *
 * Tested on Chrome 34, Firefox 29, IE 7 and 11
 */
export const update_query_string = (uri, key, value) => {
  // Use window URL if no query string is provided
  if (!uri) {
    uri = window.location.href;
  }

  // Create a dummy element to parse the URI with
  var a = document.createElement('a'),
    // match the key, optional square brackets, an equals sign or end of string, the optional value
    reg_ex = new RegExp(key + '((?:\\[[^\\]]*\\])?)(=|$)(.*)'),
    // Setup some additional variables
    qs,
    qs_len,
    key_found = false;

  // Use the JS API to parse the URI
  a.href = uri;

  // If the URI doesn't have a query string, add it and return
  if (!a.search) {
    a.search = '?' + key + '=' + value;

    // eslint-disable-next-line
    return a.href.replace(/^.*\/\/[^\/]+/, '');
  }

  // Split the query string by ampersands
  qs = a.search.replace(/^\?/, '').split(/&(?:amp;)?/);
  qs_len = qs.length;

  // Loop through each query string part
  while (qs_len > 0) {
    qs_len--;

    // Remove empty elements to prevent double ampersands
    if (!qs[qs_len]) {
      qs.splice(qs_len, 1);
      continue;
    }

    // Check if the current part matches our key
    if (reg_ex.test(qs[qs_len])) {
      // Replace the current value
      qs[qs_len] = qs[qs_len].replace(reg_ex, key + '$1') + '=' + value;

      key_found = true;
    }
  }

  // If we haven't replaced any occurrences above, add the new parameter and value
  if (!key_found) {
    qs.push(key + '=' + value);
  }

  // Set the new query string
  a.search = '?' + qs.join('&');

  // eslint-disable-next-line
  return a.href.replace(/^.*\/\/[^\/]+/, '');
};

export const scrollToRef = (ref) => {
  if (!ref.current) return;
  window.scrollTo(0, ref.current.offsetTop);
};

export const openExternalUrl = (url) => {
  const a = document.createElement('a');
  a.target = '_BLANK';
  a.href = url;
  a.click();
  a.remove();
};

export const IS_LOCALHOST = () => {
  const url = window.location.href;
  return url.includes('localhost:');
};

export const getCookie = (cname) => {
  const cookieName = `${APP_ENV}_${cname}`;
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  const item = localStorage.getItem(cname);
  if (item) return item;
  return '';
};

export const setCookie = (cname, cvalue, exdays = 1) => {
  const cookieName = `${APP_ENV}_${cname}`;
  const d = new Date(); //Create an date object
  d.setTime(d.getTime() + exdays * 1000 * 60 * 60 * 24); //Set the time to exdays from the current date in milliseconds. 1000 milliseonds = 1 second
  const expires = 'expires=' + d.toGMTString();
  // document.cookie = `${cookieName}=${cvalue};${expires};domain=${APP_DOMAIN};path=/'`;
  if (IS_LOCALHOST()) {
    Cookies.set(cookieName, cvalue, { secure: true });
    // document.cookie =
    //   cookieName +
    //   '=' +
    //   cvalue +
    //   ';' +
    //   expires +
    //   `;path=/;SameSite=None ;SameSite=None ;Secure`;
  } else {
    localStorage.setItem(cname, cvalue);
    Cookies.set(cookieName, cvalue, { secure: true });
    // document.cookie =
    //   cookieName +
    //   '=' +
    //   cvalue +
    //   ';' +
    //   expires +
    //   `;domain=${APP_DOMAIN};path=/;SameSite=None ;Secure`;
  }
};

export const setSessionCookie = (cname, cvalue) => {
  console.log('storing ', cname);
  const cookieName = `${APP_ENV}_${cname}`;
  Cookies.set(cookieName, cvalue, {
    secure: true,
  });
  // document.cookie =
  //   cookieName + '=' + cvalue + ';' + `path=/;SameSite=None ;Secure`;
};

export const forceRelative = (path) => {
  if (path[0] === '/') return path;
  return path.split('.com')[1];
};

export const getSessionCookie = (cname) => {
  const cookieName = `${cname}`;
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  console.log('CA : ', ca);

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const storeDataToSessionStorage = async (key, value) => {
  console.log('storing ', key);
  return await sessionStorage.setItem(key, value);
};

export const getDataFromSessionStorage = async (key) => {
  return await sessionStorage.getItem(key);
};

export const getUserToken = () => {
  const token = getDataFromLocalStorage('token', true);
  return token;
};

export const getOfficeUuid = () => {
  return getDataFromLocalStorage('office', true);
};
function camelCase(str) {
  if (!str) return str;
  return str
    .replace(/\s(.)/g, function (a) {
      return a.toUpperCase();
    })
    .replace(/\s/g, ' ')
    .replace(/^(.)/, function (b) {
      return b.toUpperCase();
    });
}

export const getImageUrl = (text) => {
  if (!text || typeof text !== 'string') return text;
  let icon = '';
  if (text === 'Mostly Out' || text === 'Regularly' || text === 'Weekly')
    icon = 'time';
  else if (text === 'More than \n5 litre') icon = 'filled+water';
  else if (text === '2 - 5 \nlitre') icon = 'water';
  else if (text === '1 - 2 \nlitre ') icon = 'less+water';
  else if (text === 'bedtime' || text === 'smoking') icon = text;
  else icon = camelCase(text).replace(' ', '+');

  icon = `ico-${icon}%402x.png`;

  if (text === 'Occasionally') icon = 'Group+249%403x.png';
  else if (text === 'Rarely') icon = 'Group+250%403x.png';
  else if (text === 'Sleeping') icon = `${text}%402x.png`;
  else if (text === 'alcohol') icon = `Group%403x.png`;

  return `https://allergan-dev-assets.s3.ap-south-1.amazonaws.com/AIQ/v2/${icon}`;
  // return false;
};

export const getRegimenImageUrl = (fileName) => {
  return getS3Url(`static/images/regimen/${fileName}`);
  // return false;
};

export const getS3Url = (fileName) => {
  return `https://allergan-dev-assets.s3.ap-south-1.amazonaws.com/${fileName}`;
  // return false;
};

export const getAIQUrl = (fileName) => {
  return `https://allergan-dev-assets.s3.ap-south-1.amazonaws.com/AIQ/v2/${fileName}`;
  // return false;
};

// export const getHcpUserToken = () => {
//   return getCookie('token');
//   // return false;
// };
export const deleteCookie = (cname) => {
  const cookieName = `${APP_ENV}_${cname}`;
  const d = new Date(); //Create an date object
  d.setTime(d.getTime() - 10000 * 60 * 60 * 24); //Set the time to the past. 1000 milliseonds = 1 second
  const expires = 'expires=' + d.toGMTString(); //Compose the expirartion date
  // document.cookie = `${cookieName}=;${expires};domain=${APP_DOMAIN};path=/'`; //Set the cookie with name and the expiration date
  if (IS_LOCALHOST()) {
    Cookies.remove(cookieName);
    // document.cookie =
    //   cookieName + '=' + '' + ';' + expires + `;path=/;SameSite=None ;Secure`;
  } else {
    Cookies.remove(cookieName);
    // document.cookie =
    //   cookieName +
    //   '=' +
    //   '' +
    //   ';' +
    //   expires +
    //   `;domain=${APP_DOMAIN};path=/;SameSite=None ;Secure`;
  }
};

export const hasField = (fields, field) => {
  return fields.indexOf(field) > -1;
};

export const getTitleFromField = (field) => {
  return field.name;
};

export const getPlaceholderFromField = (field) => {
  return field.name;
};

export const getNameFromField = (field) => {
  return field.slug;
};

export const getOtherDetailFields = (fields) => {
  const extraFields = groupBy(fields, 'type');
  return extraFields['extra-details'];
};

/**
 *
 * @param {string} text
 * @returns string
 */

const swapTags = (text) => {
  if (text) {
    let displayText = clone(text);
    //eslint-disable-next-line
    const tags = text.match(/@\{\{[^\}]+\}\}/gi) || [];
    tags.forEach((myTag) => {
      const tagData = myTag.slice(3, -2);
      const tagDataArray = tagData.split('||');
      const tagDisplayValue = tagDataArray[1];
      const slugValue = tagDataArray[0];
      displayText = displayText.replace(
        new RegExp(escapeRegExp(myTag), 'gi'),
        `<a href="/users/${slugValue}" style="color: #00a3e0;background-color:rgb(0 163 224 / 12%);padding: 0 3px">${tagDisplayValue}</a>`,
      );
    });
    return displayText;
  }
};

export const htmlDecodeMaster = (input, length, htmlEscape = false, emoji) => {
  if (!input) return '';
  // const origInput = input;

  input = unescape(input);

  // input = renderEmoji(input);

  let htmlToReactParser = new Parser();
  let textContent = input;

  input = new DOMParser().parseFromString(input, 'text/html');
  textContent = input.childNodes[0].childNodes[1].innerText;

  if (length && textContent.length > length) {
    input = textContent.substring(0, length);
    if (htmlEscape) {
      input = escapeHtml(input);
    }
    input += '... ';
    return input;
  }

  input = input.childNodes[0].childNodes[1].innerHTML;

  // if (escapeHtml && origInput.indexOf('&lt;') !== 0) {
  //   input = escapeHtml(input);
  // }

  let nl2br = true;

  // if (isHTML(input)) {
  //   nl2br = false;
  // }

  input = linkifyHtml(input, {
    nl2br,
    className: 'linkInside',
    defaultProtocol: 'https',
    target: {
      url: '_blank',
    },
    formatHref: {
      ticket: function (href) {
        return '/search/' + href.substring(1);
      },
    },
  });

  input = swapTags(input);

  input = htmlToReactParser.parse(input);

  return input ? input : '';
};

export const emailIsValid = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const urlIsValid = (url) => {
  var re =
    /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/;
  if (!re.test(url)) {
    return false;
  }
  return true;
};

export const seconds2time = (seconds) => {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds - hours * 3600) / 60);
  seconds = parseInt(seconds - hours * 3600 - minutes * 60);
  var time = '';

  if (hours !== 0) {
    time = hours + ':';
  }
  if (minutes !== 0 || time !== '') {
    minutes = minutes < 10 && time !== '' ? '0' + minutes : String(minutes);
    time += minutes + ':';
  }
  if (time === '') {
    time = seconds + 's';
  } else {
    time += seconds < 10 ? '0' + seconds : String(seconds);
  }
  return time;
};

export const generateUUID = () => {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const getLongTime = (n) => {
  // const num = n;
  // const hours = num / 60;
  // const rhours = Math.floor(hours);
  // const minutes = (hours - rhours) * 60;
  // const rminutes = Math.round(minutes);
  // return rhours + ' hour(s) and ' + rminutes + ' minute(s)';

  if (n === 0) {
    return 'No Case Resolved';
  }

  const now = new Date();
  const startDate = moment(now).subtract(n, 'm').toDate();
  let duration = intervalToDuration({
    start: startDate,
    end: now,
  });

  const responsetime = formatDuration(duration, {
    delimiter: ', ',
  });

  const splitArr = responsetime.split(',');
  const response = `${splitArr[0]} and ${splitArr[1]}`;

  return response;
};

export const getCsrfToken = () => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_CSRF_URL}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        setCookie('csrf-token', response.csrf_token);
        resolve(response);
      })
      .catch((error) => {
        console.log('Failed to fetch csrf');
        reject(error);
      });
  });
};
