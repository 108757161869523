import { useQuery } from '@apollo/react-hooks';
import { isEmpty, isEqual } from 'lodash';
import React, { useContext } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { getQueryParameter, parseGraphqlErrors } from '../../common/functions';
import HsLoader from '../../components/Common/HsLoader';
import { GET_INTIALIZATION_DATA } from '../Auth/query';
import { OrganizationContext } from '../Context/OrganizationContext';
import { UserContext } from '../Context/UserContext';

function Initialize(props) {
  const { user, setUser } = useContext(UserContext);
  const { organization, setOrganization } = useContext(OrganizationContext);
  const history = useHistory();

  const { loading, error, data } = useQuery(GET_INTIALIZATION_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { profile } = data;
      if (profile && !profile.isEmailVerified) {
        // history.replace(`/user-onboarding`);
      }
    },
  });

  if (loading && isEmpty(data)) return <HsLoader type="initialize" />;

  if (error) {
    const gError = parseGraphqlErrors(error);
    if (gError.code === 'FORBIDDEN') {
      history.replace(`/`);
      return <HsLoader type="initialize" />;
    }
    return <p>Error ...</p>;
  }
  let redirect_to = getQueryParameter('redirect_to') || null;

  const { profile, organizationData } = data;

  if (!isEqual(profile, user)) setUser(profile);
  if (!isEqual(organizationData, organization))
    setOrganization(organizationData);

  if (!redirect_to) return <></>;

  return <Redirect to={redirect_to}></Redirect>;
}

export default Initialize;
