import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { strings } from '../../assets/strings/all';
import UserTitleSelect from '../Common/Dropdown/UserTitleSelect';
// import CountryCodeSelect from "../Common/Dropdown/CountryCodeSelect";
import SpecialitySelect from '../Common/Dropdown/SpecialitySelect';
// import ProfessionSelect from '../Common/Dropdown/ProfessionSelect';
import { getCsrfToken, validatePassword } from '../../common/functions';
import { isEmpty } from 'lodash';
import {
  CREATE_ACCOUNT,
  GET_PRIVACY_POLICY,
  GET_TERMS_AND_CONDITIONS,
} from '../../containers/Auth/query';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';
import { useLazyQuery, useMutation } from '@apollo/client';
import HsInfoPopup from '../Common/HsInfoPopup';
import LicenseWrapper from '../Profile/LicenseWrapper';
import CountrySelect from '../Common/Dropdown/CountrySelect';
import { OrganizationContext } from '../../containers/Context/OrganizationContext';

const SignUp = (props) => {
  const [state, setState] = useState({
    showPassword: false,
    title: '',
    content: '',
    showPopup: false,
    showTrackingModal: false,
  });

  const { profile: profileString, auth: authString } = strings;
  const [errors, setErrors] = useState({});
  const { setFeedback } = useContext(FeedbackContext);
  const { organization } = useContext(OrganizationContext);

  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    userTitleSlug: '',
    specialitySlug: '',
    // professionSlug: '',
    organizationUuid: '',
    country: '',
    licenseId: '',
    licenseFile: [],
    isTncAccepted: false,
  });

  useEffect(() => {
    getCsrfToken();
  }, []);

  useEffect(() => {
    if (organization && organization.uuid) {
      const organizationUuid = organization.uuid;
      const country = organization.countries[0];
      const userTitleSlug = organization.userTitles[0];

      setUserData({
        ...userData,
        organizationUuid,
        country,
        userTitleSlug,
      });
    }
  }, [organization]);

  const [signUp, { loading, error: signupError, data }] = useMutation(
    CREATE_ACCOUNT,
    {
      onError: ({ networkError, graphQLErrors }) => {
        const error = graphQLErrors && graphQLErrors[0];

        if (error) {
          setFeedback({ message: error.message, variant: 'danger' });
        }
      },
    },
  );

  const [get_tnc, { data: tncData }] = useLazyQuery(GET_TERMS_AND_CONDITIONS, {
    onCompleted: (data) => {
      const { get_tnc } = data;

      setState({
        ...state,
        title: 'Terms and Conditions',
        content: get_tnc.value,
        showPopup: true,
      });
    },
  });

  const [get_privacy, { data: privacyData }] = useLazyQuery(
    GET_PRIVACY_POLICY,
    {
      onCompleted: (data) => {
        const { get_privacy } = data;

        setState({
          ...state,
          title: 'Privacy Policy',
          content: get_privacy.value,
          showPopup: true,
        });
      },
    },
  );

  if (loading && !isEmpty(signupError)) {
    setErrors({});
  }

  const updateUserData = (name, value) => {
    const userObj = { ...userData };

    const newUserObj = { ...userObj, [name]: value };

    setUserData(newUserObj);

    const errorObj = validateForm();

    setErrors(errorObj);
    if (!isEmpty(errorObj)) {
      return false;
    }
  };

  const validateForm = async () => {
    const errorsObj = {};
    const { errors: errorString } = authString;
    let epression =
      /^(?:(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z](?:[A-Za-z]*[A-Za-z])?)|([a-zA-Z0-9\.\-#&]{4,}))$/;
    var regex = new RegExp(epression);

    if (!userData.firstName || userData.firstName.trim() === '') {
      errorsObj.firstName = errorString.first_name_required;
    }

    if (!userData.lastName || userData.lastName.trim() === '') {
      errorsObj.lastName = errorString.last_name_required;
    }

    if (!userData.email || userData.lastName.trim() === '') {
      errorsObj.email = errorString.email_required;
    } else if (!userData.email.match(regex)) {
      errorsObj.email = errorString.incorrect_user;
    }

    // if (!userData.phoneCode) {
    //   errorsObj.phoneCode = errorString.phone_code_required;
    // }

    // if (!userData.phoneNumber) {
    //   errorsObj.phoneNumber = errorString.phone_required;
    // }

    if (!userData.password) {
      errorsObj.password = errorString.password_required;
    } else {
      const passwordValid = validatePassword(userData.password);
      if (passwordValid) {
        errorsObj.password = passwordValid;
      }

      // if (userData.password !== userData.confirmPassword) {
      //   errorsObj.confirmPassword = errorString.password_mismatch;
      // }
    }

    if (!userData.userTitleSlug) {
      errorsObj.userTitleSlug = errorString.title_required;
    }

    if (!userData.specialitySlug) {
      errorsObj.specialitySlug = errorString.speciality_id_required;
    }

    // if (!userData.professionSlug) {
    //   errorsObj.professionSlug = errorString.profession_id_required;
    // }

    // if (!userData.licenseId || userData.licenseId.trim() === '') {
    //   errorsObj.licenseId = errorString.license_no_required;
    // }

    if (!userData.licenseFile || userData.licenseFile.length === 0) {
      errorsObj.licenseFile = errorString.license_files_required;
    }

    if (isEmpty(userData.country)) {
      errorsObj.country = errorString.country_id_required;
    }

    await setErrors({ ...errorsObj });

    return Object.keys(errorsObj).length === 0;
  };

  const submitUserData = async () => {
    const signupObj = {
      firstName: userData.firstName.trim(),
      lastName: userData.lastName.trim(),
      email: userData.email.trim(),
      // phoneCode: userData.phoneCode,
      // phoneNumber: userData.phoneNumber,
      password: userData.password,
      userTitleSlug: userData.userTitleSlug,
      organizationUuid: userData.organizationUuid,
      specialitySlug: userData.specialitySlug,
      // professionSlug: userData.professionSlug,
      licenseId: userData.licenseId.trim(),
      licenseFile: userData.licenseFile,
      country: userData.country,
    };

    if (await validateForm()) {
      if (signupObj.specialitySlug) {
        signupObj.specialitySlug = userData.specialitySlug.slug;
      }

      // if (signupObj.professionSlug) {
      //   signupObj.professionSlug = userData.professionSlug.slug;
      // }

      if (signupObj.userTitleSlug) {
        signupObj.userTitleSlug = userData.userTitleSlug.slug;
      }

      if (signupObj.country) {
        signupObj.country = userData.country.iso3;
      }

      try {
        const response = await getCsrfToken();
        if (response)
          signUp({
            variables: {
              ...signupObj,
            },
          });
      } catch (error) {
        setFeedback(error.message);
      }
    }
  };

  const getPrivacyPolicy = async () => {
    if (privacyData) {
      setState({
        ...state,
        title: 'Privacy Policy',
        content: privacyData.get_privacy.value,
        showPopup: true,
      });
    } else {
      get_privacy();
    }
  };
  const getTermsAndConditions = async () => {
    if (tncData) {
      setState({
        ...state,
        title: 'Terms and Conditions',
        content: tncData.get_tnc.value,
        showPopup: true,
      });
    } else {
      get_tnc();
    }
  };

  const closePopup = async () => {
    setState({
      ...state,
      title: '',
      content: '',
      showPopup: false,
    });
  };

  return (
    <>
      {!loading && data ? (
        <>
          <div>
            <div className="auth-form-title">{authString.sign_up_success}</div>
            <div className="muted-text">
              {authString.check_email_post_signup}
            </div>
            <div className="signin-option-wrapper">
              <div className="btn-outer">
                {/* <Button as={Link} to="/cases" variant="primary">
                    
                  </Button> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <Row>
              <Col>
                <Form
                  noValidate
                  onSubmit={async (event) => {
                    event.preventDefault();
                    submitUserData();
                  }}
                >
                  <div className="auth-form-title">{authString.signup}</div>
                  <span className="muted-text">
                    {authString.signup_help_text}
                  </span>
                  <Row className="auth-form-top">
                    <Col md={2}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>{profileString.title}</Form.Label>
                        <UserTitleSelect
                          value={userData.userTitleSlug}
                          onChange={(value) =>
                            updateUserData('userTitleSlug', value)
                          }
                        />
                        {errors['userTitleSlug'] && (
                          <Form.Control.Feedback className="signup-feedback">
                            {errors['userTitleSlug']}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>{profileString.first_name}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={profileString.first_name}
                          value={userData.firstName}
                          autoComplete="off"
                          maxLength={255}
                          onChange={(e) =>
                            updateUserData('firstName', e.target.value)
                          }
                        />
                        {errors['firstName'] && (
                          <Form.Control.Feedback className="signup-feedback">
                            {errors['firstName']}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>{profileString.last_name}</Form.Label>
                        <Form.Control
                          type="text"
                          autoComplete="off"
                          placeholder={profileString.last_name}
                          value={userData.lastName}
                          maxLength={255}
                          onChange={(e) =>
                            updateUserData('lastName', e.target.value)
                          }
                        />
                        {errors['lastName'] && (
                          <Form.Control.Feedback className="signup-feedback">
                            {errors['lastName']}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>{profileString.country}</Form.Label>
                    <CountrySelect
                      placeholder={profileString.country}
                      value={userData.country}
                      onChange={(value) => {
                        {
                          updateUserData('country', value);
                        }
                      }}
                    />
                    {errors['country'] && (
                      <Form.Control.Feedback className="signup-feedback">
                        {errors['country']}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>{profileString.email_address}</Form.Label>
                    <Form.Control
                      type="email"
                      autoComplete="off"
                      placeholder={profileString.email_address}
                      value={userData.email}
                      maxLength={255}
                      onChange={(e) => updateUserData('email', e.target.value)}
                    />
                    {errors['email'] && (
                      <Form.Control.Feedback className="signup-feedback">
                        {errors['email']}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>{profileString.password}</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={state.showPassword ? 'text' : 'password'}
                        placeholder={profileString.password}
                        value={userData.password}
                        maxLength={255}
                        onChange={(e) =>
                          updateUserData('password', e.target.value)
                        }
                      />

                      <InputGroup.Append>
                        <InputGroup.Text
                          id="basic-addon1"
                          onClick={() => {
                            setState({
                              ...state,
                              showPassword: !state.showPassword,
                            });
                          }}
                        >
                          {state.showPassword ? (
                            <span className="icon-ico-view" />
                          ) : (
                            <span className="icon-ico-hide" />
                          )}
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>

                    <Form.Label>{authString.password_help_text}</Form.Label>

                    {errors['password'] && (
                      <Form.Control.Feedback className="signup-feedback">
                        {errors['password']}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>{profileString.speciality}</Form.Label>
                    <SpecialitySelect
                      placeholder={profileString.speciality}
                      value={userData.specialitySlug}
                      onChange={(value) =>
                        updateUserData('specialitySlug', value)
                      }
                    />
                    {errors['specialitySlug'] && (
                      <Form.Control.Feedback className="signup-feedback">
                        {errors['specialitySlug']}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  {/* <Form.Group controlId="formBasicEmail">
                    <Form.Label>{profileString.profession}</Form.Label>
                    <ProfessionSelect
                      placeholder={profileString.profession}
                      value={userData.professionSlug}
                      onChange={(value) =>
                        updateUserData('professionSlug', value)
                      }
                    />
                    {errors['professionSlug'] && (
                      <Form.Control.Feedback className="signup-feedback">
                        {errors['professionSlug']}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group> */}

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>{profileString.license_id_optional}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={profileString.license_id_optional}
                      value={userData.licenseId}
                      maxLength={255}
                      onChange={(e) =>
                        updateUserData('licenseId', e.target.value)
                      }
                    />
                    {errors['licenseId'] && (
                      <Form.Control.Feedback className="signup-feedback">
                        {errors['licenseId']}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Label>{profileString.license_file}</Form.Label>
                    <div className="custom-control-box">
                      <LicenseWrapper
                        userData={userData}
                        onChange={(attachments) =>
                          updateUserData('licenseFile', attachments)
                        }
                      />
                    </div>

                    {errors['licenseFile'] && (
                      <Form.Control.Feedback className="signup-feedback">
                        {errors['licenseFile']}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <HsInfoPopup
                    popUpStatus={state.showPopup}
                    title={state.title}
                    content={state.content}
                    closePopup={closePopup}
                  />

                  <Form.Group controlId="formBasicChecbox">
                    <Row>
                      <Col>
                        <div className="flex-display">
                          <Form.Check
                            className="hs-check"
                            type="checkbox"
                            onChange={(e) => {
                              updateUserData('isTncAccepted', e.target.checked);
                            }}
                            id={`onboarding-can_track_activity`}
                            name="isTncAccepted"
                            value={userData.isTncAccepted}
                            checked={userData.tnc}
                            custom={true}
                          />
                          <>
                            <span className="text-muted">
                              {strings.formatString(
                                authString.terms_condition_signup,
                                {
                                  tnc: (
                                    <span
                                      className="underlying-text"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setState({
                                          ...state,
                                          showPopup: true,
                                        });
                                        getTermsAndConditions();
                                      }}
                                    >
                                      {authString.tnc}
                                    </span>
                                  ),
                                  privacy_policy: (
                                    <span
                                      className="underlying-text"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setState({
                                          ...state,
                                          showPopup: true,
                                        });
                                        getPrivacyPolicy();
                                      }}
                                    >
                                      {authString.privacy_policy}
                                    </span>
                                  ),
                                },
                              )}
                            </span>
                          </>
                        </div>
                        {/* {user.can_track_activity} */}
                        <Form.Control.Feedback type="invalid">
                          {errors &&
                            errors.can_track_activity &&
                            errors.can_track_activity}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                  </Form.Group>

                  <div className="text-right auth-form-action-button">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={!userData.isTncAccepted || loading}
                    >
                      {!loading ? (
                        authString.signup
                      ) : (
                        <>
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          {authString.creating_account}
                        </>
                      )}
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default SignUp;
//TODO add e.stopPropagation on tnc and privacy
