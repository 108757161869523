import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Row,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CommentReportModal } from '../../components/CaseForm/components/CommentReportModal';
import {
  GET_APP_COMMENTS,
  SCREEN_COMMENT,
} from '../../components/Comment/query';
const CommentView = (props) => {
  const { id, content, isScreened, User, caseCountry } = props;
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(isScreened);
  const [screenComment] = useMutation(SCREEN_COMMENT);
  const screenMutation = async () => {
    const value = await screenComment({
      variables: {
        commentId: id,
        hasBeenScreened: !active,
      },
    });
    try {
      if (value.data.screenComment.success) {
        setActive(!active);
      }
    } catch (e) {
      alert('Something went wrong');
    }
  };
  return (
    <>
      <Card>
        <Card.Body>
          <Row className="my-2 py-2 px-3" md={{ span: 12 }}>
            <Col
              style={{
                flex: 4,
              }}
            >
              <Col
                style={{
                  cursor: 'pointer',
                }}
                sm={8}
                onClick={() => {
                  history.push('/cases/' + props.originalCase.slug);
                }}
              >
                {content}
              </Col>
              <Col
                className="mt-2"
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                }}
              >
                {`Commenter: ${User.firstName} ${User.lastName}, Country:[${caseCountry.iso3}], Case: ${props.originalCase.slug} `}
              </Col>
            </Col>
            <Col style={{ display: 'flex', gap: '25px' }}>
              <span
                style={{
                  fontSize: '12px',
                }}
              >
                Mark as screened
              </span>
              <input
                type="checkbox"
                checked={active}
                onChange={screenMutation}
              />
              <Button
                variant="outline-danger"
                onClick={(v) => {
                  setOpen(true);
                }}
              >
                Report
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <CommentReportModal
        open={open}
        id={id}
        onFinish={(v) => {
          if (v === true) {
            setActive(true);
          }
          setOpen(false);
        }}
      />
    </>
  );
};

const ViewCommentsChunk = (props) => {
  const { skip, onLoad, pageSize } = props;
  const { data, loading } = useQuery(GET_APP_COMMENTS, {
    fetchPolicy: 'network-only',
    variables: {
      skip: skip * pageSize,
      pageSize,
      isScreened: props.isScreened,
    },
  });
  useEffect(() => {
    try {
      if (data.getAppComments.comments.length === 0) {
        onLoad(true);
      } else {
        onLoad(false);
      }
    } catch (err) {
      onLoad(loading);
    }
  }, [loading, data]);
  useEffect(() => {}, [props.isScreened]);
  if (loading) {
    return <div>Loading ...</div>;
  }
  if (!data) return <div></div>;
  const { getAppComments } = data;
  return (
    <>
      {getAppComments &&
        getAppComments.comments &&
        getAppComments.comments.map((comment, idx) => {
          return (
            <>
              {comment.originalCase && (
                <CommentView key={idx} {...comment} setModal={props.setModal} />
              )}
            </>
          );
        })}
    </>
  );
};

const AppComments = (props) => {
  const [radioValue, setRadioValue] = useState('PENDING');
  const radios = [
    { name: 'Pending', value: 'PENDING' },
    { name: 'Screened', value: 'SCREENED' },
  ];
  const pageSize = 10;
  const [skip, setSkip] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const elemHeight = 300;
  const onScroll = (ev) => {
    const limit = ev.target.scrollHeight - elemHeight;
    if (limit - 30 < ev.target.scrollTop && !isLoading) {
      setSkip(skip + 1);
    }
  };
  const onLoad = (loadingStatus) => {
    setLoading(loadingStatus);
  };
  useEffect(() => {
    if (skip === -1) {
      setSkip(0);
    }
  }, [skip]);
  return (
    <>
      <Container>
        <Col>
          <h4 className="my-3">Case/Question Comments</h4>
        </Col>
        <Col className="mb-3">
          <ToggleButtonGroup type="radio" name="radio">
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={
                  radioValue === radio.value ? 'primary' : 'outline-dark'
                }
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => {
                  setRadioValue(e.currentTarget.value);
                  setSkip(-1);
                }}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Col>
        <Col
          style={{
            overflowY: 'auto',
            maxHeight: `${elemHeight}px`,
          }}
          onScroll={onScroll}
          className="py-2"
        >
          {Array(skip + 1)
            .fill(0)
            .map((chunk, indx) => (
              <ViewCommentsChunk
                isScreened={radioValue === 'SCREENED'}
                key={indx}
                {...props}
                pageSize={pageSize}
                skip={indx}
                onLoad={onLoad}
              />
            ))}
        </Col>
      </Container>
    </>
  );
};

export default AppComments;
